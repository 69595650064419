import React from 'react'

import tw from 'twin.macro'

interface Props {
  hasGuides?: boolean
  children: any
  css?: any
  id?: string
  ref?: any
  padding?: 'none' | 'normal' | 'top' | 'bottom'
}

const paddingOptions = {
  none: '',
  top: tw`pt-16 lg:pt-20`,
  bottom: tw`pb-16 lg:pb-20`,
  normal: tw`py-16 lg:py-20`,
}

const Section = ({
  hasGuides: _hasGuides = true,
  children,
  id,
  padding = 'normal',
  ref,
  ...props
}: Props) => {
  return (
    <div id={id} tw="relative max-w-screen-xl mx-auto" {...props} ref={ref}>
      <div tw="z-10 relative">
        <div css={[paddingOptions[padding]]}>{children}</div>
      </div>
    </div>
  )
}

export { Section }

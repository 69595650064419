import { graphql, useStaticQuery } from 'gatsby'

export const useHomeReviewsSection = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "5qhBoV379F4NSeGTULEAjx" } }
      ) {
        data: edges {
          node {
            sectionModules {
              ... on ContentfulMxModules {
                images {
                  id
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}

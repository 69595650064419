import React from 'react'

import 'twin.macro'
import { motion } from 'framer-motion'

import { useHomeReviewsSection } from 'content-queries/mx/home-reviews'

import { FiveStars } from '../components'
import useDisplay from '../hooks/useDisplay'
import { pressContainer, pressItems } from '../motion-variants'
import { Section } from '../section'

type reviewsInfo = {
  description: string
  id: string
  title: string
  file: {
    url: string
  }
}

const Reviews = () => {
  const { data } = useHomeReviewsSection()
  const [
    {
      node: {
        sectionModules: [reviewsInfo],
      },
    },
  ] = data

  const intersectionRef = React.useRef(null)
  const showIt = useDisplay(intersectionRef)

  return (
    <Section tw="mt-44" padding="none">
      <div
        ref={intersectionRef}
        tw="justify-center items-end relative md:(flex flex-wrap mx-8)"
      >
        <motion.div
          variants={pressContainer}
          initial="hidden"
          animate={showIt ? 'show' : 'hidden'}
          className="scroll-snap-x"
          tw="px-6 pb-6 flex space-x-6 md:(justify-center) lg:(space-x-8)"
        >
          {reviewsInfo?.images?.map((review: reviewsInfo) => {
            return (
              <motion.div
                key={review.id}
                variants={pressItems}
                tw="w-auto flex flex-col shrink-0 items-start relative md:(w-4/12)"
              >
                <FiveStars size="16px" />
                <div tw="h-12 flex items-center place-self-start md:(h-16)">
                  <img
                    tw="object-scale-down w-auto h-8"
                    src={review?.file.url}
                    alt={review.title}
                  />
                </div>
                <p tw="text-gray-600 font-light text-base text-left max-w-xs self-start md:(text-sm)">
                  {review.description}
                </p>
              </motion.div>
            )
          })}
        </motion.div>
      </div>
    </Section>
  )
}

export { Reviews }
